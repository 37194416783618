<template lang="pug">
	header.header.container
		.flex.justify-between.items-center
			Link(:to="{name: 'Demo.KPIDashboard'}" unstyled)
				img.header__logo(src="~@/assets/img/demo/demo_logo.png")
			.header__logout(@click="userLogout") Выйти

</template>

<script>
import { mapActions } from 'vuex'
import Link from '@/components/Link/Link.vue'

export default {
	components: {
		Link,
	},
	methods: {
		...mapActions('app', ['logout']),
    async userLogout() {
      await this.logout({ silent: true });
      await this.$router.push({ name: "SignIn" });
    }
	},
}
</script>

<style lang="scss" scoped>
.header {
	padding: 22px;

	&__logo {
		width: 45px;
	}

	&__logout {
		font-weight: 600;
		cursor: pointer;
	}
}
</style>