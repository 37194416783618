import api from '@/api'

export default {
	namespaced: true,
	state: {
		stores: [],
		categories: [],
		brands: [],
		regions: [],
		directory: {
			stores: {},
			categories: {},
			brands: {},
			regions: {},
		},
		secondPeriod: {
			from: '',
			to: '',
		},
	},
	getters: {
		getStoreById: ({ directory }) => (id) => ( directory.stores[id] ),
		getCategoryById: ({ directory }) => (id) => ( directory.categories[id] ),
		getBrandById: ({ directory }) => (id) => ( directory.brands[id] ),
		getRegionById: ({ directory }) => (id) => ( directory.regions[id] ),
	},
	mutations: {
		setStores(state, stores) {
			state.stores = stores;
		},
		setCategories(state, categories) {
			state.categories = categories;
		},
		setBrands(state, brands) {
			state.brands = brands;
		},
		setRegions(state, regions) {
			state.regions = regions;
		},
		setDirectory(state, { name, data }) {
			state.directory[name] = data;
		},
		saveSecondPeriod(state, secondPeriod) {
			state.secondPeriod.from = secondPeriod.from
			state.secondPeriod.to = secondPeriod.to
		},
	},
	actions: {
		async fetchStores({ commit }, params) {
			const stores = await api.demo.getStores(params);
			if (!stores) return;

			const YANDEX_POKUPKI_APP = 'Yandex.pokupki_APP'
			const YANDEX_MARKET_APP = 'Yandex.market_APP'

			const index = stores.findIndex((item) => item.name === YANDEX_POKUPKI_APP)
			if (index != -1) {
				stores[index].name = YANDEX_MARKET_APP
			}

			console.log(stores)
			commit('setStores', stores);

			const dataForDirectory = stores.reduce((acc, {id, name}) => (
				{...acc, [id]: name}), {}
			);
			commit('setDirectory', {
				name: 'stores',
				data: dataForDirectory,
			});
		},
		async fetchCategories({ commit }, params) {
			const categories = await api.demo.getCategories(params);
			if (!categories) return;
			commit('setCategories', categories);
			
			const dataForDirectory = categories.reduce((acc, {id, name}) => (
				{...acc, [id]: name}), {}
			);
			commit('setDirectory', {
				name: 'categories',
				data: dataForDirectory,
			});
		},
		async fetchBrands({ commit }, params) {
			const brands = await api.demo.getBrands(params);
			if (!brands) return;
			commit('setBrands', brands);
			
			const dataForDirectory = brands.reduce((acc, {id, name}) => (
				{...acc, [id]: name}), {}
			);
			commit('setDirectory', {
				name: 'brands',
				data: dataForDirectory,
			});
		},
		async fetchRegions({ commit }) {
			const regions = await api.demo.getRegions();
			if (!regions) return;
			commit('setRegions', regions);

			const dataForDirectory = regions.reduce((acc, {id, name}) => (
				{...acc, [id]: name}), {}
			);
			commit('setDirectory', {
				name: 'regions',
				data: dataForDirectory,
			});
		},
		saveSecondPeriod({ commit }, secondPeriod) {
			commit('saveSecondPeriod', secondPeriod)
		},
	},
}
