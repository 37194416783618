import axios from 'axios'
import UserToken from '@/helpers/LocalStorage/UserToken'

const token = UserToken.get()

import { converter } from './adapter'

const SIGN_IN = '/signin';

let headers = {
	'Content-Type': 'application/json'
}
if (token) {
	headers['Authorization'] = `Bearer ${token}`
}

export default new class Api {
	instance = axios.create({
		baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
		// baseURL: 'https://stag.rest-api.cluster.emonit.ru/api/v1',
		responseType: 'json',
		headers: headers,
	})

	constructor () {
		// Register services
		const requireService = require.context('./service', false, /.js$/)

		requireService
				.keys()
				.forEach(filename => {
					const name = /[^.](.+).js$/.exec(filename)[1]

					this[name] = requireService(filename).default(this.instance)
				})

		this.intercept(({ response }) => {

			response.use(response => {

				return converter(response.data)
			}, error => {
				if (error.response.status === 401 && error.response.data.message !== 'Unauthorized') {
					UserToken.remove();

					if (location.pathname !== SIGN_IN) {
						location.href = '/signin';
					}
				} else {
					return Promise.reject(error.response)
				}
			})
		})
	}

	intercept(fn) {
		fn(this.instance.interceptors)
	}

	install(Vue) {
		Vue.prototype.$api = this
	}
}
