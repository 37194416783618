<template lang="pug">
	main.layout
		.layout__content
			RouterView
		.layout__footer
			Footer
		DemoToggle
</template>
<script>
import Footer from '@/components/Layout/Footer.vue'
import DemoToggle from '@/components/Elements/DemoToggle.vue'

export default {
	components: {
		Footer,
		DemoToggle,
	},
}
</script>
<style lang="scss" scoped>
.layout {
	min-height: 100vh;

	&__content {
		min-height: calc(100vh - 66px); // 66px - footer height
	}
}
</style>
