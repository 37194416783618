<template lang="pug">
.filter__list
	checkbox.filter__item(
        v-for="item in items"
        :key="item.id"
        type="gray"
        size="sm"
        :id="item.id"
        :label="item.name"
        :value="item.checked"
        :subcats="item.subcats"
        :disabled="limit && !item.checked && isLimitFull"
		:class="{ main: item.filterId > 0 }"
        @change="selectCheckbox(item.id)"
		@multi-select="multiSelect(item)"
    )
</template>

<script>
import Checkbox from '@/components/Elements/Checkbox.vue'

export default {
	name: 'MultiSelect',
	components: {
		Checkbox,
	},
	props: {
		items: {
			type: [Array, Object],
			default: () => [[], {}],
		},
		limit: {
			type: Number,
			default: null,
		},
		hasSelectAll: {
			type: Boolean,
			default: true,
		},
	},

	computed: {
		arePossibleItemsChecked() {
			return this.items.filter((item) => item.checked).length
		},
		isLimitFull() {
			return this.limit <= this.arePossibleItemsChecked;
		},
		areAllItemsChecked() {
			return this.arePossibleItemsChecked === this.items.length
		},
	},

    methods: {
		selectAll(isChecked) {
			this.$emit('select-all', isChecked)
		},
        selectCheckbox(id) {
			this.$emit('select-checkbox', id);
        },
		multiSelect(item) {
			this.selectCheckbox(item.id)
			item.subcats.forEach(element => {
				this.selectCheckbox(element)
			});
		},
    },

}
</script>
<style lang="scss" scoped>
.checkbox {
	padding: 12px;
	&:hover:not(.--disabled) {
		background-color: rgba(87, 68, 214, 0.05);
	}
}
.checked {
	background-color: rgba(87, 68, 214, 0.05);
}
</style>
