<template lang="pug">
	main.layout
		.layout__header(v-if="!noHeader")
			HeaderLanding
		.layout__content
			RouterView
		DemoToggle
</template>
<script>
import HeaderLanding from '@/components/Layout/HeaderLanding.vue'
import DemoToggle from '@/components/Elements/DemoToggle.vue'

export default {
	components: {
		HeaderLanding,
		DemoToggle,
	},
	computed: {
		noHeader() {
			return this.$route.meta.noHeader
		}
	}
}
</script>
<style lang="scss" scoped>
.layout {

}
</style>